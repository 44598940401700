import { createAction } from 'redux-actions';
import { TODOS_SET_COLUMNS } from '../../constants/actions';

const setTodoColumns = createAction(TODOS_SET_COLUMNS);

export default (columns) => (dispatch, getState) => {
  const { auth: { email } } = getState();
  window.localStorage.setItem(`todoColumns:${email}`, columns.join(','));
  dispatch(setTodoColumns(columns));
};

import { connect } from 'react-redux';
import actions from '../../redux/actions';
import Dashboard from './Dashboard';
import Immutable from 'immutable';
import { getVisibleDocuments } from '../../utils/get-visible-documents';

const mapStateToProps = (state) => {
  const {
    app: { selectedMatterId, selectedClientId, features, wideLayout },
    entities,
    migration,
    todos: {
      pageSize,
      activePage,
      term,
      show,
      isLoadingTodos,
      sort,
      filterType,
      defaultTerm,
      pairStatus,
      selectedColumns
    },
    auth: {
      token: authToken,
      firmName,
      email: loginEmail
    },
    documents: {
      notesSearch
    }
  } = state;

  const attorneys = entities.get('attorneys');
  const clients = entities.get('clients');
  const matters = entities.get('matters');
  const documents = entities.get('documents');
  const matterConsistency = entities.get('matterConsistency');

  const clientList = entities.get('clientList') || Immutable.List();
  const matterList = entities.get('matterList') || Immutable.Map();

  const client = clients.get(selectedClientId) || Immutable.Map();
  const nplSuggestions = entities.getIn(['nplSuggestions', client.get('clientNumber')]) || Immutable.Map();

  const todoList = entities.get('todoList').filter(matterId => {
    const matter = matters.get(matterId);
    if (!matter) return false;

    const client = clients.get(matter.get('clientId'));
    if (!client) return false;

    return true;
  });

  return {
    attorneys,
    defaultTerm,
    migration,
    wideLayout,
    features,
    authToken,
    loginEmail,
    todoList,
    selectedMatterId,
    selectedClientId,
    clients,
    matters,
    matterConsistency,
    term,
    show,
    filterType,
    firmName,
    isLoadingTodos,
    pageSize,
    activePage,
    pairStatus,
    sortField: sort.sortField,
    sortDirection: sort.sortDirection,
    entities,
    documents,
    nplSuggestions,
    visibleDocuments: notesSearch ? getVisibleDocuments(state, false) : Immutable.List(),
    subsetIDSEnabled: Boolean(notesSearch),
    clientList,
    matterList,
    selectedColumns: selectedColumns || getSelectedColumns(loginEmail)
  };
};

export default connect(mapStateToProps, actions)(Dashboard);

function getSelectedColumns(email) {
  const columns = [
    'approved',
    'clientNumber',
    'clientName',
    'matterNumber',
    'alternateId',
    'cited',
    'uncited',
    'ptoFeeNavigator',
    'pairStatus',
    'urgent'
  ];

  const storedColumns = (window.localStorage.getItem(`todoColumns:${email}`) || '')
    .trim()
    .split(',')
    .filter(Boolean)
    .filter(col => columns.includes(col));

  if (storedColumns.length) {
    return storedColumns;
  }

  return columns;
}

import { handleActions } from 'redux-actions';

import {
  TODOS_SET_COLUMNS
} from '../../constants/actions';

const initialState = null;

export default handleActions({

  [TODOS_SET_COLUMNS]: (state, { payload }) => {
    return payload;
  }

}, initialState);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autoBind from 'class-autobind';
import Immutable from 'immutable';
import TodosFilter from './TodosFilter';
import TodosList from './TodosList';
import CollapsiblePanel from '../shared/CollapsiblePanel';
import Loading from '../shared/Loading';
import ExpandLayoutIcon from '../shared/ExpandLayoutIcon';
import AssistantModal from './AssistantModal';

export default class Dashboard extends Component {
  static propTypes = {
    activePage: PropTypes.number,
    addNotification: PropTypes.func.isRequired,
    attorneys: PropTypes.instanceOf(Immutable.Map).isRequired,
    authToken: PropTypes.string.isRequired,
    checkConsistency: PropTypes.func.isRequired,
    clearConsistency: PropTypes.func.isRequired,
    clients: PropTypes.instanceOf(Immutable.Map).isRequired,
    defaultTerm: PropTypes.string,
    documents: PropTypes.instanceOf(Immutable.Map).isRequired,
    entities: PropTypes.instanceOf(Immutable.Map).isRequired,
    features: PropTypes.object.isRequired,
    fetchAttorneyIfNeeded: PropTypes.func.isRequired,
    fetchDocumentsIfNeeded: PropTypes.func.isRequired,
    fetchTodos: PropTypes.func.isRequired,
    fetchTodosIfNeeded: PropTypes.func.isRequired,
    fileMatter: PropTypes.func.isRequired,
    filterType: PropTypes.string.isRequired,
    firmName: PropTypes.string,
    isLoadingTodos: PropTypes.bool,
    loadSyncOverlap: PropTypes.func.isRequired,
    logAction: PropTypes.func.isRequired,
    loginEmail: PropTypes.string.isRequired,
    matterConsistency: PropTypes.instanceOf(Immutable.Map).isRequired,
    matters: PropTypes.instanceOf(Immutable.Map).isRequired,
    navigateApp: PropTypes.func.isRequired,
    nplSuggestions: PropTypes.instanceOf(Immutable.Map).isRequired,
    pageSize: PropTypes.number,
    searchNplDocuments: PropTypes.func.isRequired,
    selectClientAndMatter: PropTypes.func.isRequired,
    selectedClientId: PropTypes.string,
    selectedMatterId: PropTypes.string,
    selectPage: PropTypes.func.isRequired,
    setFileReminder: PropTypes.func.isRequired,
    setFilterType: PropTypes.func.isRequired,
    setPageSize: PropTypes.func.isRequired,
    setShow: PropTypes.func.isRequired,
    setTerm: PropTypes.func.isRequired,
    show: PropTypes.string.isRequired,
    sortDirection: PropTypes.number,
    sortField: PropTypes.string,
    sortTodos: PropTypes.func.isRequired,
    subsetIDSEnabled: PropTypes.bool.isRequired,
    term: PropTypes.string.isRequired,
    todoList: PropTypes.instanceOf(Immutable.List).isRequired,
    toggleWideLayout: PropTypes.func.isRequired,
    updateMatter: PropTypes.func.isRequired,
    updateMatterFromPeds: PropTypes.func.isRequired,
    updatePairStatus: PropTypes.func.isRequired,
    uploadAutoGoldData: PropTypes.func.isRequired,
    uploadTwoWayMigration: PropTypes.func.isRequired,
    viewCategoryMatter: PropTypes.func.isRequired,
    visibleDocuments: PropTypes.instanceOf(Immutable.List).isRequired,
    wideLayout: PropTypes.bool.isRequired,
    saveBatchMatterUpdate: PropTypes.func.isRequired,
    clientList: PropTypes.instanceOf(Immutable.List),
    matterList: PropTypes.instanceOf(Immutable.Map),
    fetchMatterListIfNeeded: PropTypes.func.isRequired,
    callApi: PropTypes.func.isRequired,
    updateMatterPairStatus: PropTypes.func.isRequired,
    togglePtoFeePanel: PropTypes.func.isRequired,
    selectedColumns: PropTypes.arrayOf(PropTypes.string).isRequired,
    setTodoColumns: PropTypes.func.isRequired
  };

  static getDerivedStateFromProps(props, state) {
    if (
      props.term !== state.term ||
      props.show !== state.show ||
      props.filterType !== state.filterType
    ) {
      return {
        term: props.term,
        show: props.show,
        filterType: props.filterType,
        fetch: true
      };
    }

    return null;
  }

  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      showAssistant: this.props.features.limitedDocs
    };
  }

  componentDidUpdate() {
    if (this.state.fetch) {
      this.fetchTodos();
    }
    this.checkFilterType();
  }

  checkFilterType() {
    if (this.props.filterType === 'category' && !this.props.selectedClientId) {
      this.props.navigateApp({
        filterType: 'smart',
        term: this.props.defaultTerm
      });
    }
  }

  fetchTodos() {
    this.props.fetchTodosIfNeeded();
  }

  onClickAssistant() {
    this.setState({ showAssistant: true });
  }

  renderList() {
    if (this.props.isLoadingTodos) {
      return (
        <Loading padding='5px 0 10px 0' />
      );
    }
    return (
      <TodosList
        selectedColumns={this.props.selectedColumns}
        activePage={this.props.activePage}
        addNotification={this.props.addNotification}
        attorneys={this.props.attorneys}
        authToken={this.props.authToken}
        callApi={this.props.callApi}
        checkConsistency={this.props.checkConsistency}
        clearConsistency={this.props.clearConsistency}
        clients={this.props.clients}
        documents={this.props.documents}
        entities={this.props.entities}
        features={this.props.features}
        fetchAttorneyIfNeeded={this.props.fetchAttorneyIfNeeded}
        fetchDocumentsIfNeeded={this.props.fetchDocumentsIfNeeded}
        fileMatter={this.props.fileMatter}
        filterType={this.props.filterType}
        firmName={this.props.firmName}
        loadSyncOverlap={this.props.loadSyncOverlap}
        logAction={this.props.logAction}
        loginEmail={this.props.loginEmail}
        matterConsistency={this.props.matterConsistency}
        matters={this.props.matters}
        onClickAssistant={this.onClickAssistant}
        pageSize={this.props.pageSize}
        selectClientAndMatter={this.props.selectClientAndMatter}
        selectedClientId={this.props.selectedClientId}
        selectedMatterId={this.props.selectedMatterId}
        selectPage={this.props.selectPage}
        setFileReminder={this.props.setFileReminder}
        setPageSize={this.props.setPageSize}
        show={this.props.show}
        sortDirection={this.props.sortDirection}
        sortField={this.props.sortField}
        sortTodos={this.props.sortTodos}
        subsetIDSEnabled={this.props.subsetIDSEnabled}
        term={this.props.term}
        todoList={this.props.todoList}
        updateMatter={this.props.updateMatter}
        updateMatterFromPeds={this.props.updateMatterFromPeds}
        updateMatterPairStatus={this.props.updateMatterPairStatus}
        updatePairStatus={this.props.updatePairStatus}
        uploadAutoGoldData={this.props.uploadAutoGoldData}
        uploadTwoWayMigration={this.props.uploadTwoWayMigration}
        viewCategoryMatter={this.props.viewCategoryMatter}
        visibleDocuments={this.props.visibleDocuments}
        togglePtoFeePanel={this.props.togglePtoFeePanel}
      />
    );
  }

  renderFilter() {
    return (
      <TodosFilter
        setTodoColumns={this.props.setTodoColumns}
        selectedColumns={this.props.selectedColumns}
        searchNplDocuments={this.props.searchNplDocuments}
        nplSuggestions={this.props.nplSuggestions}
        features={this.props.features}
        clients={this.props.clients}
        selectedClientId={this.props.selectedClientId}
        defaultTerm={this.props.defaultTerm}
        term={this.props.term}
        show={this.props.show}
        filterType={this.props.filterType}
        setTerm={this.props.setTerm}
        setShow={this.props.setShow}
        setFilterType={this.props.setFilterType}
      />
    );
  }

  onClickExpand(e) {
    e.preventDefault();
    e.stopPropagation();
    this.props.toggleWideLayout();
  }

  renderHeader() {
    return (
      <span>
        Dashboard

        <ExpandLayoutIcon
          toggleWideLayout={this.props.toggleWideLayout}
          wideLayout={this.props.wideLayout}
        />
      </span>
    );
  }

  onCloseAssistant() {
    this.setState({
      showAssistant: false
    });
  }

  renderAssistantModal() {
    return this.state.showAssistant && (
      <AssistantModal
        selectClientAndMatter={this.props.selectClientAndMatter}
        saveBatchMatterUpdate={this.props.saveBatchMatterUpdate}
        fetchMatterListIfNeeded={this.props.fetchMatterListIfNeeded}
        fetchTodos={this.props.fetchTodos}
        todoList={this.props.todoList}
        navigateApp={this.props.navigateApp}
        clientList={this.props.clientList}
        matterList={this.props.matterList}
        onClose={this.onCloseAssistant}
      />
    );
  }

  render() {
    return (
      <CollapsiblePanel id='dashboard-container' header={this.renderHeader()}>
        {this.renderFilter()}
        {this.renderList()}
        {this.renderAssistantModal()}
      </CollapsiblePanel>
    );
  }
};

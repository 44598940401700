import React, { Component } from 'react';
import PropTypes from 'prop-types';
import autoBind from 'class-autobind';
import { DropdownButton, Dropdown, DropdownHeader } from 'react-bootstrap';

export default class TodoColumnsDropdown extends Component {
  static propTypes = {
    setTodoColumns: PropTypes.func.isRequired,
    selectedColumns: PropTypes.arrayOf(PropTypes.string).isRequired
  };

  static getDerivedStateFromProps(props, state) {
    return null;
  }

  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      open: false
    };
  }

  onToggle(open) {
    this.setState({ open });
  }

  onSelect(key) {
    const selectedColumns = this.props.selectedColumns.includes(key)
      ? this.props.selectedColumns.filter(col => col !== key)
      : [...this.props.selectedColumns, key];

    this.props.setTodoColumns(selectedColumns);
  }

  isSelected(key) {
    return this.props.selectedColumns.includes(key);
  }

  renderOption({ key, label }) {
    const icon = this.isSelected(key) ? 'fa fa-check-square-o text-success' : 'fa fa-square-o';

    return (
      <Dropdown.Item
        key={key}
        eventKey={key}
        onClick={() => this.onSelect(key)}
      >
        <span className={icon} style={{ marginRight: '8px' }} />
        {label}
      </Dropdown.Item>
    );
  }

  renderOptions() {
    const options = [
      { key: 'approved', label: 'Approved' },
      { key: 'clientNumber', label: 'Client/BU ID' },
      { key: 'clientName', label: 'Client Name' },
      { key: 'matterNumber', label: 'Matter ID' },
      { key: 'alternateId', label: 'Alternate ID' },
      { key: 'cited', label: 'Cited' },
      { key: 'uncited', label: 'Uncited' },
      { key: 'ptoFeeNavigator', label: 'PTO Fee Navigator' },
      { key: 'pairStatus', label: 'PAIR' },
      { key: 'urgent', label: 'Urgent' }
    ];

    return (
      <>
        {options.map(this.renderOption)}
      </>
    );
  }

  render() {
    return (
      <DropdownButton
        size='sm'
        variant='primary'
        className='no-caret'
        show={this.state.open}
        onToggle={this.onToggle}
        autoClose='outside'
        title={<span className='fa fa-cog' />}
        id='todo-columns-dropdown'
      >
        <DropdownHeader>
          Select Columns
        </DropdownHeader>
        <Dropdown.Divider />
        {this.renderOptions()}
      </DropdownButton>

    );
  }
};
